import { Component, OnInit, AfterViewInit, ViewChild, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from '../../models/login.model';
import { SignInModel } from '../../models/signin.model';
import { AlertsModel } from '../../../shared/models/alerts.model';
import { RegisterAccountRequestModel } from '../../models/register.account.request.model';
import { AccountModel } from '../../models/account.model';
import { NgbActiveModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ProspectService } from '../../services/prospect.service';

@Component({
  selector: 'app-account-coemprunteur',
  templateUrl: './account-coemprunteur.component.html',
  styleUrls: ['./account-coemprunteur.component.css']
})
export class AccountCoemprunteurComponent implements OnInit, AfterViewInit {
  login: any;
  name: any;
  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private route: Router,
    private prospectService: ProspectService,
    private _elementRef: ElementRef,
    private routewithParam: ActivatedRoute
  ) {
    this.login = this.accountService;
    this.name = name;
    // Get params from url
    this.routewithParam.queryParams.subscribe(params => ((this.signkey = params['signkey']), (this.activated = params['activated'])));
  }
  // link tabset in DOM
  @ViewChild('accountTabs')
  public accountTabs: NgbTabset;

  // Variables init
  account: LoginModel;
  UserInfos: AccountModel;
  loginForm: FormGroup;
  signInForm: FormGroup;
  forgetPasswordFrom: FormGroup;
  submitted = false;
  authenticationError = false;
  userBlocked = false;
  userNotActivated72HError = false;
  errorEmailExists = false;
  toto = false;
  alert: AlertsModel;
  singIn: SignInModel;
  registerAccountRequestModel: RegisterAccountRequestModel = new RegisterAccountRequestModel();
  showConfirmation = false;
  showForgotPassword = false;
  emailResetRequest: string;
  errorEmailNotExists = false;
  requestResetSuccess = false;
  signkey = '';
  activated = '';
  listTabsIds: string[];
  listTabsVisibility: boolean[];

  ngOnInit() {
    this.account = new LoginModel();
    this.alert = new AlertsModel();
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.minLength(8), Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)]
      ]
    });
    this.singIn = new SignInModel();
    this.signInForm = this.formBuilder.group({
      firstName: [
        ''.trim(),
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(/^([a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖòóôõöÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ' '-])*$/)
        ]
      ],
      lastName: [
        ''.trim(),
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(/^([a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖòóôõöÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ' '-])*$/)
        ]
      ],
      civilite: ['', [Validators.required]],
      telephoneMobile: ['', [Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)
        ]
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)
        ]
      ],
      cgu: ['', []],
      optin: ['', []]
    });
    this.forgetPasswordFrom = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]]
    });

    // Initialisation signinForm avec les information du coemprunteur
    if (this.signkey !== undefined && this.signkey !== '') {
      this.getDonneesProspectCoemprunteur();
    }
  }

  /***********************************************
   * INIT tabs
   **********************************************/
  ngAfterViewInit() {
    if (this.accountTabs) {
      if (this.activated === 'true') {
        // auth then redirect to universign
        setTimeout(() => {
          this.accountTabs.select('login');
        }, 0);
      } else {
        // finalise register => auth then redirect to universign
        setTimeout(() => {
          this.accountTabs.select('signin');
        }, 0);
        // }
      }      
      this.ariaAdjustments();
    }
  }
  // Control form Tab 1
  get f1() {
    return this.loginForm.controls;
  }
  // Control form Tab 2
  get f2() {
    return this.signInForm.controls;
  }
  // control form request Reset Password
  get f3() {
    return this.forgetPasswordFrom.controls;
  }

  /************************************
   * login
   ***********************************/
  onSubmitF1() {
    this.submitted = true;
    this.account = this.loginForm.value;
    this.accountService.login(this.account).subscribe(
      response => {
        this.authenticationError = false;
        this.errorEmailExists = false;
        this.userBlocked = false;
        localStorage.setItem('isLoggedIn', 'true');
        const expires_at = new Date();
        expires_at.setSeconds(expires_at.getSeconds() + response.expires_in);
        response.expires_at = expires_at.getTime();
        localStorage.setItem('tokenAuthentication', JSON.stringify(response));
        // this.getAccountInfos();
        this.userNotActivated72HError = false;
        this.activeModal.close('Connexion Ok');

        if (location.href.includes('sign-invitation')) {
          // Redirect to "SUIVI" of dashboard to sign contract
          this.route.navigate(['features/dashboard'], { queryParams: { accesPage: 'souscription' } });
        } else if (location.href.includes('home')) {
          // Redirect to Home
          this.route.navigate(['home']);
        }
      },
      error => {
        this.handleError(error);
      }
    );
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
  }
  /*****************************************
   * handleError
   ****************************************/
  private handleError(error: any) {
    if (error) {
      if (error.error != null && error.error.error_description != null) {
        if (error.error.error_description.indexOf('429 - user was blocked') !== -1) {
          this.authenticationError = false;
          this.errorEmailExists = false;
          this.userNotActivated72HError = false;
          this.userBlocked = true;
        } else if (error.error.error_description.indexOf('User was not activated + 72H !') !== -1) {
          this.authenticationError = false;
          this.errorEmailExists = false;
          this.userNotActivated72HError = true;
          this.userBlocked = false;
        } else {
          this.authenticationError = true;
        }
      } else {
        this.authenticationError = true;
      }
    }
  }
  // get token infos service call
  /* private getAccountInfos() {
    this.accountService.getAccount().subscribe(
      success => {
        this.UserInfos = success;
        localStorage.setItem('AccountInfos', JSON.stringify(this.UserInfos));
        this.accountService.sendMessage('login');
        if (localStorage.getItem('AccountInfos') !== null) {
          const infoUser = JSON.parse(localStorage.getItem('AccountInfos'));
          if (location.href.includes('account-settings')) {
            this.route.navigate(['features/account-settings']);
          } else if (infoUser.firstIcvFinal) {
            // si c'est son premier icv complet
            this.route.navigate(['features/icv-complet']); // redirect to icv complet parcours
            this.showConfirmation = true;
          } else {
            // else redirect to dashboard
            this.route.navigate(['features/dashboard']);
          }
        }
      },
      error => {
        console.log(error);
        // code for error callback
      }
    );
  } */
  /***************************************
   * signin
   **************************************/
  onSubmitF2() {
    // stop here if form is invalid
    if (this.signInForm.invalid) {
      return;
    } else {
      this.singIn = this.signInForm.value;
      const credentials: LoginModel = new LoginModel();
      this.registerAccountRequestModel = this.registerAccountRequestModel.constructModelRegister(this.singIn);

      this.accountService.registerCoemprunteurAccount(this.registerAccountRequestModel).subscribe(
        success => {
          this.errorEmailExists = false;
          credentials.username = this.singIn.email;
          credentials.password = this.singIn.password;
          this.loginAfterRegister(credentials);
        },
        error => {
          // code for error callback
          if (error.status === 400 && error != null && error.error != null && error.error.indexOf('invalid e-mail') !== -1) {
            this.errorEmailExists = true;
          } else {
            this.errorEmailExists = false;
          }
        }
      );
    }
  }
  /*****************************************************
   * login After Register
   ****************************************************/
  private loginAfterRegister(credentials: LoginModel) {
    this.accountService.login(credentials).subscribe(response => {
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('tokenAuthentication', JSON.stringify(response));
      const expires_at = new Date();
      expires_at.setSeconds(expires_at.getSeconds() + response.expires_in);
      response.expires_at = expires_at.getTime();
      localStorage.setItem('tokenAuthentication', JSON.stringify(response));
      // Redirection
      this.showConfirmation = true;
      if (location.href.includes('sign-invitation')) {
        // Redirect to "SUIVI" of dashboard to sign contract
        this.route.navigate(['features/dashboard'], { queryParams: { accesPage: 'souscription' } });
      } else if (location.href.includes('home')) {
        // Redirect to Home
        this.route.navigate(['home']);
      }
    });
  }
  /*****************************************************
   *  Request link Activation
   * @param email
   ****************************************************/
  requestActivationLink(email) {
    this.accountService.requestActivationLink(email);
  }
  /*****************************************************
   *  valider Tel Portable
   * @param telMobile
   ****************************************************/
  validerTelPortable() {
    const telMobile = this.signInForm.value.telephoneMobile;
    // Num tel doit commencer par 06 ou 07
    if (telMobile && telMobile.length === 10) {
      const reg = new RegExp('^[0-9]*$');
      /*regexp qui contrôle s'il y a des chiffes*/
      const test = reg.test(telMobile);
      if (test) {
        const TelephoneBeginWith = telMobile.slice(0, 2);
        if (TelephoneBeginWith === '07' || TelephoneBeginWith === '06') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (telMobile === '') {
      return true;
    }
  }
  /**********************************************************
   *  Function to validate if the confirm password = password
   * @param password, confirmPassword
   **********************************************************/
  // Function to validate if the confirm password = password
  validerConfirmPassword() {
    const password = this.signInForm.value.password;
    const confirmPassword = this.signInForm.value.confirmPassword;
    if (confirmPassword && password && confirmPassword === password) {
      return true;
    } else {
      return false;
    }
  }
  /**********************************RESET PASSWORD***************************************/
  showForgetPassword() {
    this.showForgotPassword = true;
  }
  requestResetPassword() {
    this.accountService.resetPassword(this.emailResetRequest).subscribe(
      response => {
        this.errorEmailNotExists = false;
        this.requestResetSuccess = true;
      },
      error => {
        if (error.error === 'e-mail address not registered' && error.status === 400) {
          this.errorEmailNotExists = true;
        }
        this.requestResetSuccess = false;
        console.log(error);
      }
    );
  }
  closeRequest() {
    this.showForgotPassword = false;
  }

  /**********************************************
   *  Charger les données du prospect coemprunteur
   *********************************************/
  getDonneesProspectCoemprunteur() {
    this.accountService.getCoEmprunteur(this.signkey).subscribe(
      (prospect: any) => {
        this.signInForm.setValue({
          firstName: prospect.prenom.trim(),
          lastName: prospect.nom.trim(),
          email: prospect.email,
          civilite: prospect.sexe,
          telephoneMobile: prospect.telPortable,
          password: '',
          confirmPassword: '',
          optin: false,
          cgu: false
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  /** ARIA SECTION START  **/
  ariaAdjustments() {
    setTimeout(() => {
      let tabSetElement = this._elementRef.nativeElement.querySelector('ngb-tabset');
      if (tabSetElement) {
        let listUl = tabSetElement.getElementsByClassName('nav-pills')[0];
        listUl.addEventListener("keydown", function (e) {
          if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
          }
        }, false);
        let list = tabSetElement.getElementsByClassName('nav-item');
        let panel = tabSetElement.getElementsByClassName('tab-content')[0];
        panel.setAttribute('tabindex', "-1");
        this.listTabsIds = [];
        this.listTabsVisibility = [];
        if (list && list.length) {
          for (let index = 0; index < list.length; index++) {
            list[index].setAttribute('role', "presentation");
            let child = list[index].children[0];
            if (child) {
              this.listTabsVisibility = [...this.listTabsVisibility, !child?.innerHTML.includes('disabled')]
              this.listTabsIds = [...this.listTabsIds, child?.id]
              if (child?.innerHTML.includes('disabled')) {
                child.setAttribute('disabled', "");
              }              
              child.setAttribute('tabindex', "-1");
              if (child.getAttribute('aria-selected') == "true") {
                child.setAttribute('tabindex', "0");
              }
            }
          }
        }
      }
    }, 0);
  }

  navigationFleche(event, isForward) {
    let direction = isForward ? 'forward' : 'back';
    let currentIndex = this.listTabsIds.includes(document?.activeElement?.id) ? this.listTabsIds.indexOf(document.activeElement.id) : this.listTabsIds.indexOf(this.accountTabs.activeId);
    let nextIndex = this.getNextElement(direction, currentIndex, this.listTabsIds.length);
    if (this.listTabsVisibility[nextIndex]) {
      document.getElementById(this.listTabsIds[nextIndex])?.focus();
    }
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  getNextElement(direction, currentIndex, length) {
    let nextIndex = currentIndex;
    if (direction == "back") {
      if (currentIndex - 1 < 0) {
        nextIndex = length - 1;
      } else {
        nextIndex--
      }
    }
    if (direction == "forward") {
      if (currentIndex + 1 >= length) {
        nextIndex = 0;
      } else {
        nextIndex++
      }
    }
    return nextIndex;
  }

  selectTab() {
    if (this.listTabsIds.includes(document?.activeElement?.id)) {
      this.accountTabs.select(document?.activeElement?.id);
    }
  }

  tabChangeEvent() {
    this.ariaAdjustments();
  }

  
  /** ARIA SECTION END  **/
}
