<footer role="contentinfo">
  <div class="contenerFooter maxWidth clearfix">
    <div class="partFooter">
        <h1 class="footer-title">A propos</h1>
        <ul>
            <li>
                <a href="" (click)="$event.preventDefault();goToQuiSommesNous()" target="_blank">Qui sommes nous ?</a>
            </li>
            <li>
                <a href="https://www.notice-financement.bpce.fr/Notice_information_donnees_personnelles_Financement.pdf" target="_blank">Vos données<br>personnelles</a>
            </li>
            <li>
                <a [routerLink]="['/features/cgu/']" target="_blank" (click)="tagCgu()">CGU</a>
            </li>
            <li>
                <a [routerLink]="['/features/mentions/']" target="_blank" (click)="tagMentions()">Mentions légales</a>
            </li>
        </ul>
    </div>
    <div class="partFooter">
        <h1 class="footer-title">Aide et assistance</h1>
        <ul>
            <li>
                <a [routerLink]="['/features/faq/']" target="_blank" (click)="tagFaq()">FAQ</a>
            </li>
            <li>
                <a href="" target="_blank" (click)="$event.preventDefault();tagCookies()">Personnaliser vos préférences</a>
            </li>
            <li>
                <a href="./files/accessibilite.pdf" target="_blank" (click)="tagAccessibilite()">Accessibilité : partiellement conforme</a>
            </li>
            <li>
                <a href="https://www.notice-financement.bpce.fr/note_informations_cookies_traceurs_offre_pret_personnel.pdf" target="_blank">Gestion des cookies</a>
            </li>
        </ul>

    </div>

</div>
<div class="prefooter clearfix">
     <img src="./assets/images/header-footer/logo_bpce.png" alt="Groupe BPCE">
</div>
</footer>
